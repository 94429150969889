.join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}
.left-j > hr {
  border: 2px solid var(--orange);
  width: 10.5rem;
  border-radius: 20%;
}
.right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder {
  color: var(--lightgray);
}
.btn-j {
  background: var(--orange);
  color: white;
}
@media screen and (max-width: 1080px) {
  .join {
    flex-direction: column;
    gap: 1rem;
  }
  .left-j {
    font-size: x-large;
  }
  .right-j {
    padding: 2rem;
  }
  .email-container {
    padding: 10px 12px;
    gap: 10px;
  }
  .email-container > input {
    width: 12rem;
  }
  .btn-j {
    width: 5rem;
    font-size: 11px;
    font-weight: 700;
  }
}
